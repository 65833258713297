import React, { useState, useEffect } from "react";
import { Template, categories as cats } from "../template";
import { useParams } from "react-router-dom";
import { API, domain } from "../../api";
import { Table as Btable, Button, Spinner, Image, Form } from "react-bootstrap";
import { useStore } from "effector-react";
import { EditProduct } from "./EditProduct";
import { AddSale } from "./AddSale";
import styled from "styled-components";
import { FilterInProducts } from "../FilterInProducts";
import { $auth } from "../Login";

export const Products = () => {
  const { id } = useParams();
  const [products, setProducts] = useState<any>([]);
  const [responseData, setResponseData] = useState<any>([]);
  const [modalAddSale, setModalAddSale] = useState<any>();
  const [parameters, setParameters] = useState<{ product_name: string }>();
  const [modalEditProduct, setModalEditProduct] = useState<any>();
  const [loading, setLoading] = useState<any>();
  const [viewCols, setViewCols] = useState<any>(false);
  const categories = useStore(cats);
  const auth = useStore<any>($auth);

  useEffect(() => {
    document.querySelectorAll(".variable")?.forEach(element => {
      if (viewCols) {
        //@ts-ignore
        element.classList.add("able");
      } else {
        //@ts-ignore
        element.classList.remove("able");
      }
    });


  }, [viewCols])

  const filterProductsData = (data: any) => {
    console.log('data', data);

  }

  const get_products = (product_name?: string) => {
    setLoading(true);
    API.get_products({ category: id, product_name }).then((response) => {
      console.log(filterProductsData(response.data.products));

      setProducts(response.data.products);
      setResponseData(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (parameters) {
      get_products(parameters.product_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  useEffect(() => {
    get_products();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  const table = (
    <Btable striped bordered hover size="sm" className="table-striped w-auto">
      <thead style={{ whiteSpace: "nowrap" }}>
        <tr>
          {responseData?.table_head?.map((x: any, i: any) => <th key={i} className={["Закупочная цена", "Себестоимость"].indexOf(x) >= 0 ? "variable" : ""}>{x}</th>)}
          <th />
          {/* <th>id</th>
          <th>Заметки</th>
          <th>Фото</th>
          <th>Наименование</th>
          <th>Код товара</th>
          {responseData?.shops?.map((shop: { name: string }) => (
            <th key={shop.name}>р.ц. {shop.name}</th>
          ))}
          {responseData?.shops?.map((shop: { name: string }) => (
            <th key={shop.name}>Склад {shop.name}</th>
          ))}
          <th>Закупочная цена</th>
          <th>Себестоимость</th>
          <th /> */}
        </tr>
      </thead>
      <tbody>
        {products?.map(
          (
            product: {
              image: string;
              name: string;
              code: string;
              id: string;
              prices: {
                shop_id: string;
                price: string;
              }[];
              stock: {
                count: string;
                shop_id: string;
              }[];
              cost_type: "";
              purchase_price: any;
              cost_price: number;
              note: string;
              title_color: string;
            },
            i: number
          ) => (
            <tr key={i}>
              <td title="id">{product.id}</td>
              <td title="Заметки">{product.note}</td>
              <td title="изображение товара">
                {product.image && (
                  <Image
                    src={domain + "/images/" + product.image}
                    thumbnail
                    width={211}
                    className="max-width-initial width-200"
                    style={{
                      maxWidth: "initial",
                    }}
                  />
                )}
              </td>
              <td
                title="наименование товара"
                style={{ color: product.title_color }}
              >
                {product.name}
              </td>
              <td title="код товара">{product.code}</td>
              {responseData?.shops?.map((shop: { id: string }) => (
                <>
                  {(() => {
                    const val = product.prices.find(
                      (price_item: { shop_id: string; price: string }) =>
                        price_item.shop_id === shop.id
                    )?.price;
                    const output =
                      Math.round(Number(val)) &&
                      Math.round(Number(val)).toLocaleString();
                    if (val !== undefined) {
                      return <td key={shop.id} title={`розничная цена ${shop.id}`}>{output ? `${output} ₽` : "0"}</td>
                    }
                  })()}
                </>
              ))}
              {responseData?.shops?.map(
                (shop: { id: string | number | undefined }) => (
                  <td
                    key={shop.id}
                    className="cell_prices"
                    title={`количество на складе ${shop.id}`}
                  >
                    {(() => {
                      const count = product.stock.find(
                        (stock_item: { shop_id: string; count: string }) =>
                          stock_item.shop_id === shop.id
                      )?.count;
                      return count ? count : 0;
                    })()}
                  </td>
                )
              )}
              {product.purchase_price !== undefined && <td title="закупочная цена" className="variable">
                {Number(product.purchase_price).toLocaleString()} ₽
              </td>}
              {product.cost_price !== undefined && <td title="себестоимость" className="variable">
                {Math.round(product.cost_price).toLocaleString()} ₽
              </td>}
              <td
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                <Button
                  variant="primary"
                  onClick={() => {
                    setModalAddSale({ product });
                  }}
                  className="mr-2"
                  size="sm"
                >
                  Продать
                </Button>
                {auth?.user?.role === "1" && (
                  <>
                    <Button
                      variant="primary"
                      className="mr-2"
                      onClick={() => {
                        setModalEditProduct({ product });
                      }}
                      size="sm"
                    >
                      Изменить
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        API.send_to_archive({ product_id: product.id }).then(
                          ({
                            data,
                          }: {
                            data: { success: boolean; errors: any };
                          }) => {
                            // get_products()
                            if (data.success) {
                              get_products();
                            } else {
                              alert(JSON.stringify(data.errors));
                            }
                          }
                        );
                      }}
                      size="sm"
                    >
                      В архив
                    </Button>
                  </>
                )}
              </td>
            </tr>
          )
        )}
        <tr></tr>
      </tbody>
    </Btable>
  );

  return (
    <Template title={categories?.find((cat: any) => cat.id === id)?.name}>
      {/* <Mail /> */}
      <FilterInProducts
        filterHandle={(pamparams: { product_name: string }) => {
          setParameters(pamparams);
        }}
      />
      <Styled>

        {loading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
            <>
              {products?.length
                ? <>
                  {auth?.user?.role === "1" && <Button size="sm" variant="outline-secondary" type="button" onClick={() => setViewCols(!viewCols)} className="mb-3">Допштуки {viewCols ? "выкл" : "вкл"}</Button>}
                  <div>
                    {table}
                  </div>
                </>
                : "Товары по заданным критериям не найдены"}
            </>
          )}
        <AddSale
          modalAddSale={modalAddSale}
          setModalAddSale={setModalAddSale}
          responseData={responseData}
          reload={() => get_products(parameters?.product_name)}
        />
        <EditProduct
          modalEditProduct={modalEditProduct}
          setModalEditProduct={setModalEditProduct}
          responseData={responseData}
          aaa={modalEditProduct}
          reload={() => get_products(parameters?.product_name)}
        />
      </Styled>
    </Template>
  );
};

const Styled = styled.div`
  thead {
    overflow-y: auto;
    height: 100px;
  }
  th {
    text-align: left;
    position: sticky;
    top: 0;
    background: #fff;
  }
  td {
    vertical-align: middle;
  }
  .variable{
    display: none;
  }
  .variable.able{
    display: table-cell;
  }
`;
