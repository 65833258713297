import { API } from "../../api";
import React, { useEffect } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { validation } from "../helpers/validation";
import {
  CustomInput,
  CustomTextarea,
  CustomSelect,
  CustomFileInput,
} from "./fields";
import Swal from "sweetalert2";
import { constants } from "../../constants";
import { categories as cats } from "../template";
import { useStore } from "effector-react";


export const markups = [
  {
    label: "фиксированная",
    value: "fix",
  },
  {
    label: "ручная",
    value: "handle",
  },
  {
    label: "процент",
    value: "percent",
  },
];

export const EditProduct = ({
  modalEditProduct,
  setModalEditProduct,
  responseData,
  reload,
}: any): any => {
  // useEffect(() => {
  //   API.get_product({ product_id: "123" });
  // }, []);
  const categories = useStore(cats);

  useEffect(() => {
    // modalEditProduct && alert();
  }, [modalEditProduct]);
  const onSubmit = (values: any) => {

    const formData = new FormData();

    if (values?.product_image)
      formData.append("file", values?.product_image[0]);

    for (const key in values) {
      formData.append(
        key,
        (() => {
          if (["stock_counts", "retail_prices"].indexOf(key) !== -1) {
            return JSON.stringify(values[key]);
          } else {
            return values[key];
          }
        })()
      );
    }

    API.edit_product(formData).then((response) => {
      if (response?.data?.success) {
        Swal.fire({
          title: "Успешно!",
          icon: "success",
          confirmButtonText: "Ок",
        }).then(() => {
          setModalEditProduct(false);
          reload();
        });
      } else if (response?.data?.errors) {
        Swal.fire({
          title: "Ошибка!",
          icon: "error",
          text: response?.data?.errors.join(", "),
          confirmButtonText: "Ок",
        });
      }
    });
  };
  const { shops } = responseData;
  return (
    <>
      <Modal
        show={modalEditProduct ? true : false}
        onHide={setModalEditProduct}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={{
            id: modalEditProduct?.product?.id,
            title_color: modalEditProduct?.product?.title_color,
            name: modalEditProduct?.product?.name,
            purchase_price: modalEditProduct?.product?.purchase_price,
            cost_type: modalEditProduct?.product?.cost_type,
            cost_value: modalEditProduct?.product?.cost_value,
            note: modalEditProduct?.product?.note,
            code: modalEditProduct?.product?.code,
            image: modalEditProduct?.product?.image,
            //@ts-ignore
            category_id: modalEditProduct?.product?.category_id,
            retail_prices: shops
              ? shops.map((shop: any) => ({
                ...shop,
                // price_type: stock.find(
                //   (stock_item: any) =>
                //     stock_item.shop_id === shop.id &&
                //     modalEditProduct?.product?.id === stock_item.product_id
                // )?.count,
                price_type: modalEditProduct?.product?.prices.find(
                  (price_item: any) => price_item.shop_id === shop.id
                )?.price_type,
                // price_type: "fix",
                price_count: modalEditProduct?.product?.prices.find(
                  (price_item: any) => price_item.shop_id === shop.id
                )?.price_count,
              }))
              : [],
            stock_counts: shops
              ? shops.map((shop: { id: string; name: string }) => ({
                ...shop,
                count: (() => {
                  const val = modalEditProduct?.product?.stock?.find(
                    (stock_item: { shop_id: string; count: string }) => {
                      return stock_item.shop_id === shop.id;
                    }
                  )?.count;
                  return val ? val : 0;
                })(),
              }))
              : [],
          }}
          mutators={{
            setPrice: (args, state, utils) => {
              utils.changeValue(state, "sum", () => args);
            },
            ...arrayMutators,
          }}
          render={({ form, handleSubmit, values, errors }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Редактировать {modalEditProduct?.product?.name}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <CustomInput
                      lable="Наименование"
                      name="name"
                      type="input"
                      placeholder="Наименование"
                      validation={validation.required}
                    />
                  </Col>
                  <Col>
                    <CustomInput
                      lable="Код товара"
                      name="code"
                      type="input"
                      placeholder="Код товара"
                    // validation={validation.required}
                    />
                  </Col>
                </Row>
                <CustomSelect
                  name="category_id"
                  lable="Категория товара"
                  // validation={validation.required}
                  placeholder="Категория"
                  options={[
                    ...categories?.map((category: any) => ({
                      value: category.id,
                      label: category.name,
                    })),
                  ]}
                />
                <Row>
                  <Col>
                    <CustomFileInput
                      lable="Изображение товара"
                      name="product_image"
                    // validation=any;
                    />
                  </Col>
                  <Col>
                    <CustomTextarea
                      lable="Заметки"
                      name="note"
                    // validation={validation.required}
                    />
                  </Col>
                </Row>

                <CustomInput
                  lable="Закупочная цена"
                  name="purchase_price"
                  type="number"
                  placeholder="Закупочная цена"
                  validation={validation.required}
                />
                <Row className="align-items-end">
                  <Col>
                    <CustomSelect
                      lable="Цвет"
                      name="title_color"
                      color={true}
                      options={[...constants.product_title_colors]}
                      placeholder="цвет"
                    // validation={validation.required}
                    />
                  </Col>
                </Row>
                <Row className="align-items-end">
                  <Col>
                    <CustomSelect
                      lable="Себестоимость"
                      name="cost_type"
                      options={markups}
                      placeholder="тип наценки"
                      validation={validation.required}
                    />
                  </Col>
                  <Col>
                    <CustomInput
                      lable=""
                      name="cost_value"
                      type="number"
                      placeholder="значение"
                      validation={validation.required}
                    />
                  </Col>
                </Row>

                <FieldArray name="retail_prices">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <Row className="align-items-end" key={index}>
                        <Col>
                          <CustomSelect
                            lable={`Розн. цена ${shops[index].name}`}
                            name={`${name}.price_type`}
                            options={markups}
                            placeholder="тип наценки"
                            validation={validation.required}
                          />
                        </Col>
                        <Col>
                          <CustomInput
                            lable=""
                            name={`${name}.price_count`}
                            type="number"
                            validation={validation.required}
                          />
                        </Col>
                      </Row>
                    ))
                  }
                </FieldArray>
                <Row className="align-items-end">
                  <FieldArray name="stock_counts">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <Col key={index}>
                          <CustomInput
                            lable={`К-во в ${shops[index].id}`}
                            name={`${name}.count`}
                            type="number"
                          />
                        </Col>
                      ))
                    }
                  </FieldArray>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setModalEditProduct(null);
                  }}
                >
                  Отмена
                </Button>
                <Button variant="primary" type="submit">
                  Подтвердить
                </Button>
              </Modal.Footer>
            </form>
          )}
        />
      </Modal>
    </>
  );
};
