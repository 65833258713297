export const constants = {
  product_title_colors: [
    {
      label: "Черный",
      value: "black",
    },
    {
      label: "Зеленый",
      value: "green",
    },
    {
      label: "Красный",
      value: "red",
    },
    {
      label: "Синий",
      value: "blue",
    },
    {
      label: "Коричневый",
      value: "brown",
    },
    {
      label: "Рыжий",
      value: "orange",
    },
  ]
}