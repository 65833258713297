import React, { useEffect, useState } from "react";
import { API } from "../api";
import { Template } from "./template";
import {
  Button,
  Card,
  Table as Btable,
  Form as Bform,
  Spinner,
} from "react-bootstrap";
import styled from "styled-components";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { validation } from "./helpers/validation";
import Swal from "sweetalert2";

const tables = [
  "birm_products",
  "birm_prices",
  "birm_stock",
  "birm_users",
  "birm_sales",
];

export const TableDb = () => {
  const [data, setData] = useState<any>([]);
  const [params, setPArams] = useState<any>([]);

  const [loading, setLoading] = useState(false);

  const getData = () => {
    setLoading(true);
    API.get_table_db({
      table_id: params?.tname ? params.tname : tables[0],
      settings: params,
    }).then((response: { data?: any; success?: boolean }) => {
      if (response.data.success) {
        setData(response.data.data);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, [params]);

  const ths = data.length && Object.keys(data[0]);

  const outPut = (
    <>
      <Btable striped bordered hover size="sm" className="table-striped w-auto">
        <thead>
          <tr>
            {data.length && Object.keys(data[0]).map((x: any) => <th>{x}</th>)}
            <td />
          </tr>
        </thead>
        <tbody>
          {data.map((row: any) => (
            <tr>
              {Object.entries(row).map((x: any) => (
                <td title={x[0]}>{x[1]}</td>
              ))}
              <td>
                <Button
                  variant={"danger"}
                  onClick={() => {
                    Swal.fire({
                      title: "Вы уверены?",
                      icon: "warning",
                      showCancelButton: true,
                    }).then((x) => {
                      if (x.value) {
                        API.delete_row({
                          bname: params.tname,
                          row_conditions: row,
                        }).then((resp) => {
                          if (resp.data.success) {
                            getData();
                            Swal.fire({
                              title: "Ok",
                              icon: "success",
                            });
                          }
                        });
                        // API.delete_row({
                        //   bname: "string",
                        //   row_id: "string",
                        // });
                      }
                    });
                  }}
                >
                  удалить
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Btable>
    </>
  );

  return (
    <Template title="БД">
      <Styled>
        <Filter
          filterHandle={(values: any) => {
            setPArams(values);
          }}
          col_names={ths}
        />
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
            outPut
          )}
      </Styled>
    </Template>
  );
};

const Styled = styled.div`
  thead {
    overflow-y: auto;
    height: 100px;
  }
  th {
    position: sticky;
    top: 0;
    background: #fff;
  }
`;

export const Filter = ({ filterHandle, col_names }: any) => {
  return (
    <>
      <Card className="bg-secondary mb-5">
        <Card.Body>
          <Form
            onSubmit={filterHandle}
            initialValues={
              {
                // year: 2020,
                // category: categories[0]?.id,
              }
            }
            render={({ handleSubmit, values, errors }) => (
              <Bform onSubmit={handleSubmit} inline>
                <Field name="tname">
                  {(props) => (
                    <Bform.Group
                      controlId="exampleForm.SelectCustom"
                      className="mr-2"
                    >
                      <Bform.Control
                        as="select"
                        custom
                        {...props.input}
                        size="sm"
                      >
                        {/* <option>Категория</option> */}
                        <option>название таблицы</option>
                        {tables.map(
                          (year: any, i: string | number | undefined) => (
                            <option value={year} key={i}>
                              {year}
                            </option>
                          )
                        )}
                      </Bform.Control>
                    </Bform.Group>
                  )}
                </Field>
                <Field name="col_name" validate={validation.required}>
                  {(props) => (
                    <Bform.Group
                      controlId="exampleForm.SelectCustom"
                      className="mr-2"
                    >
                      <Bform.Control
                        as="select"
                        custom
                        {...props.input}
                        size="sm"
                      >
                        <option>название колонки</option>
                        {col_names &&
                          col_names.map(
                            (year: any, i: string | number | undefined) => (
                              <option value={year} key={i}>
                                {year}
                              </option>
                            )
                          )}
                      </Bform.Control>
                    </Bform.Group>
                  )}
                </Field>
                <Field name="value">
                  {(props) => (
                    <Bform.Group className="mr-2">
                      <Bform.Control
                        placeholder="значение"
                        {...props.input}
                        size="sm"
                      ></Bform.Control>
                    </Bform.Group>
                  )}
                </Field>
                {["value", "col_name", "tname"].map((x: any) => (
                  <OnChange name={x}>
                    {() => {
                      filterHandle(values);
                    }}
                  </OnChange>
                ))}

                {/* <Field name="category">
                  {(props) => (
                    <Bform.Group
                      controlId="exampleForm.SelectCustom"
                      className="mr-2"
                    >
                      <Bform.Control
                        as="select"
                        custom
                        {...props.input}
                        size="sm"
                      >
                        {[
                          ...categories?.map((category: any, i: any) => ({
                            label: category.name,
                            value: category.id,
                          })),
                        ].map((x, i) => (
                          <option value={x.value} key={i}>
                            {x.label}
                          </option>
                        ))}
                      </Bform.Control>
                    </Bform.Group>
                  )}
                </Field> */}
                {/* <Button type="submit" size="sm">
                  Сформировать
                </Button> */}
              </Bform>
            )}
          />
        </Card.Body>
      </Card>
    </>
  );
};
