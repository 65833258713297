import React from "react";
import "./App.scss";
import { Routes } from "./routes/index";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
